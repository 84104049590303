var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CSidebar',{attrs:{"fixed":"","minimize":_vm.minimize,"show":_vm.show},on:{"update:show":function($event){_vm.show=$event}}},[_c('CSidebarBrand',{attrs:{"imgFull":{
      width: 118,
      height: 100,
      alt: 'Logo',
      src: 'img/brand/iccs101-logo.svg',
    },"imgMinimized":{
      width: 118,
      height: 46,
      alt: 'Logo',
      src: 'img/brand/iccs101-logo.svg',
    }}}),_c('CRenderFunction',{attrs:{"flat":"","content-to-render":_vm.nav}}),_c('CSidebarMinimizer',{staticClass:"d-md-down-none",nativeOn:{"click":function($event){_vm.minimize = !_vm.minimize}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }