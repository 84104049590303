<template>
  <CSidebar fixed :minimize="minimize" :show.sync="show">
    <CSidebarBrand
      :imgFull="{
        width: 118,
        height: 100,
        alt: 'Logo',
        src: 'img/brand/iccs101-logo.svg',
      }"
      :imgMinimized="{
        width: 118,
        height: 46,
        alt: 'Logo',
        src: 'img/brand/iccs101-logo.svg',
      }"
    />
    <CRenderFunction flat :content-to-render="nav" />
    <CSidebarMinimizer
      class="d-md-down-none"
      @click.native="minimize = !minimize"
    />
  </CSidebar>
</template>

<script>
import _nav from "./_assn_nav";

export default {
  name: "TheSidebar",
  data() {
    return {
      minimize: false,
      show: "responsive",
      assignments: [],
    };
  },
  mounted() {
    this.$root.$on("toggle-sidebar", () => {
      const sidebarOpened = this.show === true || this.show === "responsive";
      this.show = sidebarOpened ? false : "responsive";
    });
    this.$root.$on("toggle-sidebar-mobile", () => {
      const sidebarClosed = this.show === "responsive" || this.show === false;
      this.show = sidebarClosed ? true : "responsive";
    });
    this.getAssignments();
  },
  methods: {
    async getAssignments() {
      const res = await this.axios.get("/assignment/1/");
      this.assignments = res.data.tasksets;
    },
  },
  computed: {
    nav() {
      const assnNavs = this.assignments.map((item) => {
        return {
          _name: "CSidebarNavItem",
          name: item.display_name,
          to: `/assignment/${item.id}`,
          icon: "cil-cursor",
        };
      });
      const navItems = [{ ..._nav[0] }];
      navItems[0]._children = [..._nav[0]._children, ...assnNavs];
      return navItems;
    },
  },
};
</script>
